
import { Component, Vue } from 'vue-property-decorator'
import { TableData } from '@/types/scenarios'
import { KeepAlive } from '@/utils/decorators'
@Component({
  name: 'scenariosList'
})
@KeepAlive
export default class extends Vue {
  private searchInfo = {
    scenariosName: '',
    typeId: ''
  }

  private typeList = []

  private tableData: TableData[] =[]

  private page = 1
  private total = 0
  private size = 10
  private loading=false

  created () {
    this.getTypeList()
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.scenarios.selectDispatchScenariosByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  searchData () {
    this.page = 1
    this.getData()
  }

  // 事件类型
  getTypeList () {
    this.$axios.get(this.$apis.dispatch.selectDispatchTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  deleteMachine (scenariosId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios
        .post(this.$apis.scenarios.deleteDispatchScenarios, { scenariosId })
        .then(() => {
          this.$message.success('删除成功')
          this.searchData()
        })
    }).catch((err) => {
      console.log(err)
    })
  }

  onAdd () {
    this.$router.push({
      name: 'scenariosAdd'
    })
  }

  onUpdate (scenariosId: string) {
    this.$router.push({
      name: 'scenariosEdit',
      params: { scenariosId }
    })
  }

  onDetail (scenariosId: string) {
    this.$router.push({
      name: 'scenariosDetail',
      params: { scenariosId }
    })
  }
}
